.tableLarge {
  position: relative;
  width: 100%;
  height: 75%;

  .dataTable {
    display: flex;
    flex-direction: column;

    height: 100%;
  } 

  background-color: #212121;
};

.hiddenRow {
  opacity: 0.5;
};

  