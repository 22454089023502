.container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 8px 0px;

  & .settings {
    position: absolute;
    top: calc(50% - 20px);
    right: 28px;

    width: 80px;
    height: 40px;

    display: flex;
    flex-direction: row;
    // align-items: flex-end;
  }

  @media (max-width: 1464px) { 
    justify-content: space-between;
    padding-right: 42px;

    & .settings {
      top: 0px;
      right: 0px;
      width: fit-content;
      flex-direction: column;
    }
  }
}