.chartExtraSettings {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap: 16px;
} 

.zijdChartExtraSettings {
}