.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authors, .history {
  width: 100%;
  padding: 24px 164px;
  margin-top: 32px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 920px) { 
    padding: 0 8px;
  }
}

.history {
  margin-top: 0;
  p, h6 { 
    text-indent: 52px; 
    width: 100%;

    @media (max-width: 920px) { 
      text-indent: 26px;
    }
  }
  h6 {
    @media (max-width: 920px) { 
      font-size: 18px;
    }
  }
}

.authorBlock {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 920px) { 
    flex-direction: column;
  }
}

.infoBlock {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;

  margin-left: 24px;

  @media (max-width: 920px) { 
    margin-left: 0;
  }
}


.authorImage {
  width: 20%;

  @media (max-width: 920px) { 
    width: 60%;
    margin-bottom: 8px;
  }
}


.historyImage {
  width: 100%;
  margin: 24px 0px;

  @media (max-width: 920px) { 
    margin: 8px 0px;
  }
}

.carouselPhotos {
  width: 80%;
  padding: 48px 0;

  @media (max-width: 920px) { 
    width: 100%;
  }
}