.modalContent {

  width: 100%;
  height: 100%;

  .topCenter {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;

    .import {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .upload {
      margin: 0 20px;
    }

    .highlight {
      color: #00bcd4;
      font-weight: 500;
    }
  }

  .data {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 80%;
    margin-top: 28px;

    .input {
      width: 30%;
      height: 100%;
    }

    .vgpTable {
      width: 36%;
      height: 100%;

      margin: 0 24px;
    }

    .graphs {
      width: 34%;
      height: 100%;
      margin-top: 16px;
    
      background-color: #000;
    
      .graphLarge {
        position: relative;
        width: 100%;
        height: 100%;
    
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    
        background-color: #212121;
      }
    
      box-sizing: border-box;
    }
  }

  @media screen and (max-width: 1400px) {
    .data {
      height: 78%;

      .vgpTable {
        margin: 0 12px;
      }
    }
  }
  
}

.vgpRes {
  position: absolute;
  bottom: -36px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .copyResult {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-height: 80px;
    width: 320px;
  
    padding: 4px 8px;
    box-sizing: border-box;
  
    border: 1px dashed #000;
    border-radius: 8px;
    cursor: pointer;
  
    .colCenter, .col {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      box-sizing: border-box;
    }
  }

  @media screen and (max-width: 1400px) {
    flex-direction: row;
    gap: 8px;
  }
}

