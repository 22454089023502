.classicResults {
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.uncontrolled {
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;    
}

.formControl {
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.controlled {
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 24px;
  .inputs {
    width: 100%;
    height: fit-content;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 0 0 20px 0;

    .input {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      
      &>div {
        width: 90%;
      }
      // &>:nth-child(2n) {
      //   margin-left: 10px;
      // }
    }
  }
}

.copyResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 4px;
  box-sizing: border-box;

  border: 1px dashed #000;
  border-radius: 8px;
  cursor: pointer;
}