.helper {
  position: fixed;
  left: 4px;
  bottom: 6px;

  width: 42px;
  height: 42px;

  display: flex;
  align-items: center;
  justify-content: center;
  
  color: #119dff;
  font-weight: 400;

  border: 1px solid #119dff;
  border-radius: 8px;
  cursor: pointer;

  box-sizing: border-box;

  &:hover {
    color: #ffb74d;
    font-weight: 600;
  
    border-color: #ffb74d;
  }
}

.modalContent {
  padding: 24px;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
}

.link {
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonWrapper {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.clearLocalStorage {
  background-color: black;
  border: 2px solid red;

  font-weight: 600;
  font-size: 18px;
  color: white;

  width: fit-content;

  padding: 4px;
  box-sizing: border-box;

  cursor: pointer;
}