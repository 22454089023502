.container {
  position: relative;
  padding: 72px 120px 52px 120px;
  margin: 48px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 920px) { 
    margin: 0px 0px;
    padding: 16px 16px 16px 16px;
  }
}

.card {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 32px 96px;

  .cardText {
    width: 80%;
    height: 100%;
  }

  .cardImage {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 920px) { 
    padding: 16px 0px;
    flex-direction: column!important;

    .cardText {
      width: 100%;
      margin: 0px!important;
    }
  }
}