.controlPanel {
  position: relative;
  max-height: 10%;
  max-width: 100%;

  display: flex;
  flex-direction: row;
  background-color: #000;
  gap: 32px;
  box-sizing: border-box;

  @media screen and (max-width: 1400px) {
    gap: 16px;
  }
}

.data {
  position: relative;
  min-height: 80%;
  max-height: 100%;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;
  background-color: #000;
  gap: 32px;

  .tables {
    position: relative;
    width: 37.5%;
    min-width: 37.5%;

    display: flex;
    flex-direction: column;
    background-color: #000;
    gap: 16px;

    .tableSmall {
      position: relative;
      width: 100%;
      height: 25%;

      background-color: #212121;
    }

    box-sizing: border-box;
  }

  .graphs {
    position: relative;
    width: 62.5%;

    display: flex;
    flex-direction: row;
    background-color: #000;
    gap: 16px;

    .column {
      position: relative;
      width: 36%;
      height: 100%;
      
      display: flex;
      flex-direction: column;
      gap: 16px;

      :last-child {
        margin-bottom: 0px;
      }

      :first-child {
        margin-top: 0px;
      }
    }

    .graphSmall {
      position: relative;
      height: 50%;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      
      background-color: #212121;
    }

    .graphLarge {
      position: relative;
      width: 64%;
      height: 100%;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      background-color: #212121;
    }

    box-sizing: border-box;
  }

  @media screen and (max-width: 1400px) {
    gap: 16px;

    .tables {
      width: 58%;
      gap: 8px;
    }

    .graphs {
      width: 42%;

      .graphLarge {
        width: 100%;
      }

      .column {
        display: none;
      }

      .graphSmall {
        display: none;
      }
    }
  }
}