.metadata {
  width: 37.5%;
  min-width: 37.5%;
  max-height: 100%;
  background-color: #000;

  .table {
    width: 100%;
    height: 100%;
    background-color: #212121;

    display: flex;
    align-items: flex-end;

    background-color: #212121;
  }

  box-sizing: border-box;
}

.tinyHeader {

  &>div {
    max-height: 24px;
    line-height: 24px;
  }
}

.tableLarge {
  position: relative;
  width: 100%;
  height: 75%;

  .dataTable {
    display: flex;
    flex-direction: column;

    height: 100%;
  } 

  background-color: #212121;
};

.hiddenRow {
  opacity: 0.5;
};

  