.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 72px 240px 52px 240px;
  margin: 48px 0 0 0;
  gap: 16px;

  box-sizing: border-box;
  
  @media (max-width: 920px) { 
    padding: 48px 8px;
  }
}

.rowContainer {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.rightBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}