.navButtons {
  position: relative;
  height: 80%;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 4px 8px;
  gap: 8px;

  & a {
    text-decoration: none;
  }

}