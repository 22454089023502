.appContainer {
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 16px;
  padding: 8px;
  box-sizing: border-box;
  
  background-color: #000;
};

.top {
  max-height: 10%;
  min-width: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: row;
  background-color: #000;
  gap: 32px;

  .settings {
    // width: 37.5%;
    width: fit-content;
    
    background-color: #000;

    .appSettings {
      width: 100%;
      height: 100%;
      
      display: flex;
      align-items: center;

      background-color: #212121;
    }

    box-sizing: border-box;
  }

  .navigation {
    // width: 62.5%;
    width: 100%;
    
    background-color: #000;

    .pages {
      width: 100%;
      height: 100%;
      
      display: flex;
      align-items: center;

      background-color: #212121;
    }

    box-sizing: border-box;
  }
};

.dropFiles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  // user-select: none;

  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 65px;
  letter-spacing: 0em;
  text-align: left;
};

@media screen and (max-width: 1400px) {

  .appContainer {
    gap: 8px;
    padding: 4px;
  }

  .top {
    max-height: 6%;
    gap: 16px;

    .settings, .navigation {
      box-sizing: border-box;
    }
  }
}