.container {
  overflow-y: auto;
  overflow-x: hidden;
}

.hotkeyRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 12px 36px 12px 12px;

  border-bottom: 1px dashed #ccc;

  .hotkeyBlock {

    display: flex;
    flex-direction: row;
    align-items: center;

    .hotkey {
      min-width: 64px;
      height: fit-content;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      border-radius: 6px;
      // padding: 0 6px;

      &:hover {
        cursor: pointer;
        // box-shadow: 0px 0px 5px 1px #119dff;
      }
    } 
  }
}

.saveOrReload {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: 16px;
}