.buttons {

  height: 80%;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 4px 8px;
  gap: 8px;

}

@media screen and (max-width: 1400px) {
  .buttons {
    padding: 0 8px;

    span, button {
      // padding: 8px 4px;
    }
  }
}