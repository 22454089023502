.instruments {
  width: 100%;
  background-color: #000;
  overflow-x: auto;

  box-sizing: border-box;

  .dataSettings {
    max-width: 100%;
    height: 100%;
    background-color: #212121;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    &>:first-child {
      margin-left: 4px;
    }

    overflow-x: auto;
    overflow-y: hidden;

    background-color: #212121;
    padding: 2px 0px;
    box-sizing: border-box;
  }

}