.tableSmall {
  position: relative;
  width: 100%;
  height: 25%;

  background-color: #212121;
}

.current_dark {
  color: #ce93d8;
  font-weight: 600;
};

.current_light {
  color: #9c27b0;
  font-weight: 600;
};

.editableCell_dark {
  background-color: #ce93d825;
};

.editableCell_light {
  background-color: #9c27b025;
};