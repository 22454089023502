.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  gap: 16px;
}

.upload {
  width: 660px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
}

@media screen and (max-width: 1140px) {
  .upload {
    width: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 720px) {
  .upload {
    padding: 0 24px;
  }
}

.dropContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border: 1px dashed #474c50;
  border-radius: 12px;

  box-sizing: border-box;
}