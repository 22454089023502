.container {
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  overflow-y: auto !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}