.articlesBlock {
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    display: flex;
    justify-content: center;
    align-items: center;
  
    border-radius: 12px;
    padding: 6px;
    background-color: #fff;
  }
}

.articles {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0px 64px 24px 64px;
  gap: 96px;
  box-sizing: border-box;


  @media (max-width: 920px) { 
    padding: 0px;
    gap: 48px;
    flex-wrap: wrap;
  }
}

.article {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 16px;
}