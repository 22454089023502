.container {
  width: 100%;
  height: 100%;

  display: flex;
}

.dataContainer {

  width: 100%;
  height: 100%;

  .import {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .upload {
      margin: 0 20px;
    }

    .highlight {
      color: #00bcd4;
      font-weight: 500;
    }
  }

  .data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    .input {
      width: 30%;
      height: 100%;
    }

    .vgpTable {
      width: 36%;
      height: 100%;

      margin: 0 24px;
    }

    .graphs {
      width: 100%;
      height: 100%;
      margin-top: 16px;
    
      background-color: #000;
    
      .graphLarge {
        position: relative;
        width: 100%;
        height: 100%;
    
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    
        background-color: #212121;
      }
    
      box-sizing: border-box;
    }
  }
  
}

.result {
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .resultRow {
    display: flex;
    flex-direction: row;
  }
}

.controls {
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 0px 0 16px 0;
}