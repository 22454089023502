.container {
  position: relative;
  padding: 72px 240px 52px 240px;
  margin: 48px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1464px) { 
    padding: 92px 36px 52px 36px;
  }

  @media (max-width: 920px) { 
    padding: 92px 8px 52px 8px;
  }
}

.tabs {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  min-height: 120px;
  box-sizing: border-box;
}

.indicator {
  position: absolute;
  width: 100%;
  height: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 168px;
  bottom: -32px;
  box-sizing: border-box;

  @media (max-width: 920px) { 
    padding: 0 36px;
  }

  &:hover {
    & .indicatorItem {
      height: 9px;
    }
  }

  & .indicatorItem {
    height: 7px;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
    }
  }

  & .active {
    box-shadow: 0px 0px 5px 1px #119dff;
  }
}

.controlLeft {
  position: absolute!important;
  top: 50%;
  transform: translateY(-50%) translateX(-150%);
  left: 0;
  z-index: 1;
  background-color: #fff;
  border-radius: 12px!important; 
  box-shadow: 0px 0px 5px 1px #119dff;
  @media (max-width: 920px) { 
    display: none!important;
  }
}

.controlRight {
  position: absolute!important;
  top: 50%;
  transform: translateY(-50%) translateX(150%);
  right: 0;
  z-index: 1;
  background-color: #fff;
  border-radius: 12px!important;
  box-shadow: 0px 0px 5px 1px #119dff;
  @media (max-width: 920px) { 
    display: none!important;
  }
}