.controlPanel {
  position: relative;
  max-height: 10%;
  width: 100%;

  display: flex;
  flex-direction: row;
  background-color: #000;
};

.data {
  position: relative;
  min-height: 80%;
  max-height: 100%;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;
  background-color: #000;
  gap: 32px;

  .tables {
    position: relative;
    width: 58%;

    display: flex;
    flex-direction: column;
    background-color: #000;
    gap: 16px;

    .tableSmall {
      position: relative;
      width: 100%;
      height: 25%;

      background-color: #212121;
    }

    box-sizing: border-box;
  }

  .graphs {
    position: relative;
    width: 42%;

    display: flex;
    flex-direction: row;
    background-color: #000;

    .graphLarge {
      position: relative;
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      background-color: #212121;
    }

    box-sizing: border-box;
  }
  
  @media screen and (max-width: 1400px) {
    gap: 16px;

    .tables {
      width: 58%;
      gap: 8px;
    }

    .graphs {
      width: 42%;

      .graphLarge {
        width: 100%;
      }
    }
  }
}

